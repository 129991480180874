<template>
  <v-container fluid class="down-top-padding">
    <div v-if="!isFetching">
      <v-row>
        <v-col cols="12">
          <card-organization action="edit" :cardData="cardData">
            <v-autocomplete
              no-filter
              :items="organizations"
              v-model="organizationSelected"
              item-value="metadata.id"
              @change="changeOrganization"
              label="Selecione a organização"
              item-text="data.company.name.trade"
              @focus="bindScrollListener"
              :loading="organizationsLoading"
            ></v-autocomplete>
            <v-progress-linear indeterminate v-if="loadOrganization" color="cyan"></v-progress-linear>
          </card-organization>
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col cols="12">
          <!-- {{ organizationSelected }} -->
          <card-information-dashboard
            v-if="activeOrganization.data"
            :counters="activeOrganization.data.counters"
            :key="key"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <card-list-dashboard
            heading="Administradores"
            :items="adms"
            :fetch="fetchAdm"
            footerUrl="/organization/admins"
            footerText="Visualizar administradores"
            class="mb-8"
          ></card-list-dashboard>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="12" md="12">
          <v-card flat rounded>
            <v-skeleton-loader v-bind="attrs" class="ma-0" type="article, actions"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" v-for="i in 4" :key="i">
          <v-card flat rounded>
              <v-skeleton-loader v-bind="attrs" type="list-item-avatar-three-line"></v-skeleton-loader>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" v-for="i in 2" :key="i">
          <v-skeleton-loader v-bind="attrs" type="card-heading, table-heading@2"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CardOrganization from '@/components/cards/CardOrganization.vue'
import CardInformationDashboard from '@/components/cards/CardInformationDashboard.vue'
import CardListDashboard from '@/components/cards/CardListDashboard.vue'
export default {
  components: {
    CardListDashboard,
    CardInformationDashboard,
    CardOrganization
  },
  data: () => ({
    key: 0,
    isFetching: true,
    fetchAdm: false,
    attrs: {
      boilerplate: true,
      elevation: 2
    },
    organizationsLoading: false,
    organizationPage: 1,
    organizationSelected: [],
    data: [],
    loadOrganization: false,
    cardData: {
      image:
        'https://www.youbilingue.com.br/blog/wp-content/uploads/2019/06/capa-blog-diferenciar-escola-min.png',
      gradient: true,
      editButton: true,
      editUrl: '/organization/edit'
    },
    adms: []
  }),
  computed: {
    ...mapGetters({
      organizations: 'organization/organizations',
      activeOrganization: 'organization/activeOrganization'

    })
  },
  methods: {
    ...mapState(['organizations']),
    ...mapActions('organization', ['getOrganizations', 'getOrganization']),
    bindScrollListener () {
      const token = localStorage.getItem('jwtToken')
      setTimeout(() => {
        const scroll = document.body.querySelectorAll('.v-menu__content')[0]
        scroll.addEventListener('scroll', async () => {
          if (scroll.scrollHeight - scroll.clientHeight < scroll.scrollTop + 1) {
            this.organizationPage++
            this.organizationsLoading = true
            try {
              await this.$store.dispatch('organization/getOrganizations', { token, page: this.organizationPage, organizations: this.organizations })
              this.organizationsLoading = false
            } catch (error) {
            }
          }
        })
      }, [500])
    },
    handleClick (url) {
      this.$router.push({ path: url })
    },
    async loadData () {
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')
      await this.$axios
        .get(`/organizations/${id}/people?roles[]=ADMIN&roles[]=TECHNICAL&roles[]=FINANCIAL&roles[]=ORG_PEDAGOGICAL_COORDINATOR`)
        .then(response => {
          this.adms = response.data.items
          this.fetchAdm = true
        })
      this.organizationSelected = this.organizations.find(
        x => x.metadata.id === id
      )
    },
    async changeOrganization (val) {
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')
      if (id !== val) {
        this.loadOrganization = true
        try {
          await this.getOrganization(val)
          await this.loadData()
        } catch (e) {
        } finally {
          this.loadOrganization = false
          this.key++
        }
      }
    },
    stopLoading () {
      this.loadData()
      this.isFetching = false
    }
  },
  watch: {
    activeOrganization (val) {
      if (val) {
        this.stopLoading()
      }
    }
  },
  async mounted () {
    const token = localStorage.getItem('jwtToken')
    await this.$store.dispatch('organization/getOrganizations', { token })
    if (this.activeOrganization) {
      this.stopLoading()
    }
  }
}
</script>
<style scoped>
.primary-gradient-background {
  border: 0 !important;
  background: rgb(30, 136, 229) !important;
  background: linear-gradient(
    180deg,
    rgba(30, 136, 229, 1) 0%,
    rgba(30, 136, 229, 0.5) 100%
  ) !important;
}
.position-full {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -24px;
}
</style>
